import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getFromApi, postToApi } from "../lib/api";
import Alert from "@mui/material/Alert";
import { checkPro, clearToken, setPro } from "../lib/user.auth";
import { isBrowser } from "../lib/config";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 23,
    fontSize: 14,
    border: `0px solid #ccc`,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
  },
}))(Badge);

const Dashboard = () => {
  const { t } = useTranslation();
  const [instagram, setInstagram] = useState("");
  const [count_instagram, setInstagramCount] = useState(0);
  const [youtube, setYoutube] = useState("");
  const [count_youtube, setYoutubeCount] = useState(0);
  const [facebook, setFacebook] = useState("");
  const [count_facebook, setFacebookCount] = useState(0);
  const [twitter, setTwitter] = useState("");
  const [count_twitter, setTwitterCount] = useState(0);
  const [saved, setSaved] = useState(false);
  const [nextmail, setNextMail] = useState("");
  const [notifications, setNotifications] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendingTestMail, setSendingTestMail] = useState(false);
  const [email, setEmail] = useState("");

  const checkLogin = async () => {
    const result = await getFromApi("/1.0/check-token");
    if (result.code !== 200) {
      clearToken();
      navigate("/logout");
    } else {
      setPro(result.pro);
      setNextMail(result.nextmail);
      setEmail(result.email);
    }
  };

  const requestTestMail = async () => {
    setSendingTestMail(true);
    const result = await getFromApi("/1.0/test-mail");
    if (result.code === 200) {
      setNextMail(result.nextmail);
    }
  };

  const save = async () => {
    setSaved(true);
    await postToApi("/1.0/instagram", { name: instagram });
    await postToApi("/1.0/twitter", { name: twitter });
    await postToApi("/1.0/youtube", { name: youtube });
    await postToApi("/1.0/facebook", { name: facebook });
    getInstagram();
    getTwitter();
    getYoutube();
    getFacebook();
    setTimeout(() => setSaved(false), 2000);
  };

  const getInstagram = async () => {
    const result = await getFromApi("/1.0/instagram");
    if (result.code === 200) {
      setInstagram(result.name);
      setInstagramCount(result.follower);
    }
  };

  const getFacebook = async () => {
    const result = await getFromApi("/1.0/facebook");
    if (result.code === 200) {
      setFacebook(result.name);
      setFacebookCount(result.likes);
    }
  };

  const getYoutube = async () => {
    const result = await getFromApi("/1.0/youtube");
    if (result.code === 200) {
      setYoutube(result.name);
      setYoutubeCount(result.subscriber);
    }
  };

  const getTwitter = async () => {
    const result = await getFromApi("/1.0/twitter");
    if (result.code === 200) {
      setTwitter(result.name);
      setTwitterCount(result.follower);
    }
  };

  const checkNotifications = async () => {
    const result = await getFromApi("/1.0/check-token");
    if (result.code === 200 && result.active === true) {
      setActive(true);
      return true;
    }
    setActive(false);
    return false;
  };

  const showNotifications = async () => {
    if (await checkNotifications()) {
      return <NotificationsActiveIcon />;
    }
    return <NotificationsOffIcon />;
  };

  const changeNotifications = async () => {
    setShowButton(false);
    await postToApi("/1.0/change-notifications", {});
    setNotifications(await showNotifications());
  };

  const runCheck = async () => {
    if (!notifications) setNotifications(await showNotifications());
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      save();
    }
  };

  const loader = async () => {
    await runCheck();
    await checkLogin();
    await getInstagram();
    await getTwitter();
    await getYoutube();
    await getFacebook();
    setLoading(false);
  };

  useEffect(() => {
    // first check url on new token
    if (process.env.NODE_ENV === "development") {
      console.log("wird geladen...");
    }
    if (isBrowser()) {
      loader();
    }
  }, []);

  return (
    <Layout>
      <Seo title="Dashboard" />
      {!loading ? (
        <>
          <h1>
            Dashboard {notifications}&nbsp;
            <span className="smallfont">{email}</span>
          </h1>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid style={{ marginBottom: 20 }}>
                <StyledBadge
                  badgeContent={count_instagram}
                  max={999999999}
                  color="primary"
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="instagram"
                    disabled={!checkPro()}
                    label="Instagram "
                    name="instagram"
                    value={instagram}
                    onChange={(input) => setInstagram(input.target.value)}
                    InputProps={{
                      endAdornment: <InstagramIcon />,
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    style={{ minWidth: 350 }}
                  />
                  {!checkPro() && (
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: 120,
                        marginTop: 30,
                        fontSize: 38,
                        fontWeight: 800,
                        color: "red",
                        transform: "rotate(-20deg)",
                      }}
                    >
                      {t("GET PRO")}
                    </div>
                  )}
                </StyledBadge>
                <Typography
                  style={{ color: "#2f2f2f", fontSize: 12, cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://www.instagram.com/" + instagram);
                  }}
                >
                  https://www.instagram.com/
                  <Typography
                    component="span"
                    style={{ color: "#000080", fontWeight: 500, fontSize: 12 }}
                  >
                    {instagram}
                  </Typography>
                </Typography>{" "}
              </Grid>
              <Grid style={{ marginBottom: 20 }}>
                <StyledBadge
                  badgeContent={count_facebook}
                  max={999999999}
                  color="primary"
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="facebook"
                    label="Facebook "
                    name="facebook"
                    value={facebook}
                    onChange={(input) => setFacebook(input.target.value)}
                    InputProps={{
                      endAdornment: <FacebookIcon />,
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    style={{ minWidth: 350 }}
                  />
                </StyledBadge>
                <Typography style={{ color: "#2f2f2f", fontSize: 12 }}>
                  <span
                    style={{
                      color: "#2f2f2f",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open("https://www.facebook.com/" + facebook);
                    }}
                  >
                    https://www.facebook.com/
                    <Typography
                      component="span"
                      style={{
                        color: "#000080",
                        fontWeight: 500,
                        fontSize: 12,
                      }}
                    >
                      {facebook}
                    </Typography>
                  </span>
                  &nbsp;-&nbsp;
                  <a href={`/app/facebook/${facebook}`} title="insights">
                    show insights
                  </a>
                </Typography>{" "}
              </Grid>
              <Grid style={{ marginBottom: 20 }}>
                <StyledBadge
                  badgeContent={count_youtube}
                  max={999999999}
                  color="primary"
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="youtube (channel-id or user)"
                    label="YouTube"
                    disabled={!checkPro()}
                    name="youtube"
                    value={youtube}
                    onChange={(input) => setYoutube(input.target.value)}
                    InputProps={{
                      endAdornment: <YouTubeIcon />,
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    style={{ minWidth: 350 }}
                  />
                  {!checkPro() && (
                    <div
                      style={{
                        position: "absolute",
                        marginLeft: 120,
                        marginTop: 30,
                        fontSize: 38,
                        fontWeight: 800,
                        color: "red",
                        transform: "rotate(-20deg)",
                      }}
                    >
                      {t("GET PRO")}
                    </div>
                  )}
                </StyledBadge>
                <Typography
                  style={{ color: "#2f2f2f", fontSize: 12, cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://www.youtube.com/" + youtube);
                  }}
                >
                  https://www.youtube.com/[channel or user]/
                  <Typography
                    component="span"
                    style={{ color: "#000080", fontWeight: 500, fontSize: 12 }}
                  >
                    {youtube}
                  </Typography>
                </Typography>
              </Grid>
              <Grid>
                <Alert severity="error">
                  X formerly twitter has changed the API. In version 2, access
                  to the follower numbers is limited and no longer freely
                  available. <br />
                  As a result, X is no longer supported.
                </Alert>
              </Grid>
              <Grid>
                <StyledBadge
                  badgeContent={count_twitter}
                  max={999999999}
                  color="primary"
                >
                  <TextField
                    disabled={true}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="twitter"
                    label="X username"
                    name="twitter"
                    value={twitter}
                    onChange={(input) => setTwitter(input.target.value)}
                    InputProps={{
                      endAdornment: <TwitterIcon />,
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    style={{ minWidth: 350 }}
                  />
                </StyledBadge>
                <Typography
                  style={{ color: "#2f2f2f", fontSize: 12, cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://x.com/" + twitter);
                  }}
                >
                  https://x.com/
                  <Typography
                    component="span"
                    style={{ color: "#000080", fontWeight: 500, fontSize: 12 }}
                  >
                    {twitter}
                  </Typography>
                </Typography>
              </Grid>
              <br />
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={saved}
                onClick={() => {
                  save();
                }}
              >
                {saved ? <DoneAllIcon /> : <>{t("save")}</>}
              </Button>
              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={6}>
              {active && (
                <p>
                  You'll receive your next follower update on{" "}
                  <strong>{nextmail}</strong> direct to your inbox.
                </p>
              )}
              <hr />
              {checkPro() ? (
                <p>
                  <Alert severity="info">More coming soon...</Alert>{" "}
                </p>
              ) : (
                <Alert severity="info">
                  You're running on <strong>free tier</strong>. Please upgrade{" "}
                  <a href="/pricing" title="pro">
                    here to pro
                  </a>{" "}
                  for using also <b>Instagram</b> and <b>YouTube</b>.
                </Alert>
              )}
              <div>
                {showButton && (
                  <>
                    <hr />
                    {active && <>Stop the hell mailing me! </>} <br />
                    Click{" "}
                    <a
                      herf="#"
                      onClick={() => {
                        changeNotifications();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      here
                    </a>{" "}
                    to {active ? "disable" : "enable"} the notifications.
                  </>
                )}
                <br />
                <br />
              </div>
              <div>
                {sendingTestMail ? (
                  <>
                    Your <strong>test</strong> is scheduled and will be there
                    within in next 5 minutes.
                  </>
                ) : (
                  <>
                    {active && (
                      <>
                        <hr />
                        <small>
                          Send me a test mail report{" "}
                          <a
                            herf="#"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              requestTestMail();
                            }}
                          >
                            now (click)
                          </a>
                          .
                        </small>
                      </>
                    )}
                  </>
                )}
                <br />
                <br />
              </div>
              {!checkPro() && (
                <div>
                  <hr />
                  <table
                    align="center"
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tbody>
                      <tr>
                        <td colSpan="2" style={{ border: 0 }}>
                          <a
                            href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=oxza23uPtpfgASUS&u=${email}&t=${Math.round(
                              new Date() / 1000
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              alt="0"
                              src={`///tracking.inboxads.com/api/src/?host=my-follower.com&z=oxza23uPtpfgASUS&u=${email}&t=${Math.round(
                                new Date() / 1000
                              )}`}
                              useMap="#maimap0_gfxYwx8LVQ1W9QEU"
                            />
                          </a>
                          <map name="maimap0_gfxYwx8LVQ1W9QEU">
                            <area
                              shape="rect"
                              coords="0,0,970,250"
                              href={`//tracking.inboxads.com/api/href/?host=my-follower.com&z=oxza23uPtpfgASUS&u=${email}&t=${Math.round(
                                new Date() / 1000
                              )}`}
                              target="_blank"
                              rel="noreferrer"
                              alt="a0"
                            />
                          </map>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </Layout>
  );
};

export default Dashboard;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
